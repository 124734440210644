import { Spinner } from "components/loader";
import Loadable from "react-loadable";

export const Dashboard = Loadable({
  loader: () => import("pages/admin/dashboard"),
  loading: () => <Spinner />,
});

export const SearchDocument = Loadable({
  loader: () => import("pages/admin/search-document"),
  loading: () => <Spinner />,
});

export const FolderContainer = Loadable({
  loader: () => import("pages/admin/folder-container"),
  loading: () => <Spinner />,
});

export const DocumentViewer = Loadable({
  loader: () => import("pages/admin/document-viewer"),
  loading: () => <Spinner />,
});

export const ControlPanel = Loadable({
  loader: () => import("pages/admin/control-panel"),
  loading: () => <Spinner />,
});
export const MangerUsers = Loadable({
  loader: () => import("pages/admin/control-panel/components/manager-users"),
  loading: () => <Spinner />,
});

export const MangerGroups = Loadable({
  loader: () => import("pages/admin/control-panel/components/manager-groups"),
  loading: () => <Spinner />,
});

export const MangerIndexes = Loadable({
  loader: () => import("pages/admin/control-panel/components/manager-indexes"),
  loading: () => <Spinner />,
});
export const ManagerSetting = Loadable({
  loader: () =>
    import("pages/admin/control-panel/components/manger-global-setting"),
  loading: () => <Spinner />,
});

export const ManagerAnnoucement = Loadable({
  loader: () =>
    import("pages/admin/control-panel/components/manager-annoucement"),
  loading: () => <Spinner />,
});

export const NewDocument = Loadable({
  loader: () => import("pages/admin/new-document"),
  loading: () => <Spinner />,
});

export const Profile = Loadable({
  loader: () => import("pages/admin/profile"),
  loading: () => <Spinner />,
});

export const Setting = Loadable({
  loader: () => import("pages/admin/setting"),
  loading: () => <Spinner />,
});

export const Spooler = Loadable({
  loader: () => import("pages/admin/spooler"),
  loading: () => <Spinner />,
});

export const DocumentApproval = Loadable({
  loader: () => import("pages/admin/document-approval"),
  loading: () => <Spinner />,
});

export const Workflow = Loadable({
  loader: () => import("pages/admin/workflow"),
  loading: () => <Spinner />,
});
