/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { useAppDispatch } from "redux/store";

export interface ICommonReducer {
  containers: number[];
  cids: any[];
}

const initialState: ICommonReducer = {
  containers: [],
  cids: [],
};

interface IPayload {
  containers?: number;
  cid?: any;
}

interface IAction {
  payload: IPayload;
  type: string;
}
const commonSlice = createSlice({
  name: "containers",
  initialState,
  reducers: {
    setSelectedContainers: (state, action: IAction) => {
      if (action?.payload?.cid) {
        const isExist = state.containers?.includes(action?.payload?.cid);
        if (isExist) {
          const filtered = state.containers?.filter(
            (x) => x !== action.payload.cid,
          );
          state.cids = filtered;
        } else {
          state.cids = [...state.cids, action.payload.cid];
        }
      }
    },
  },
});

export const { setSelectedContainers } = commonSlice.actions;

export const useCommonActions = () => {
  const dispatch = useAppDispatch();
  return {
    setSelectedContainers: (payload: IPayload) =>
      dispatch(setSelectedContainers(payload)),
  };
};
const { reducer } = commonSlice;

export default reducer;
