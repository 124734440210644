import useUserInfo from "hooks/useUserInfo";
import { Route, Routes } from "react-router-dom";
import PermissionsLayout from "./PermissionProtect";
import ProtectedRoute from "./ProtectedRoute";
import AppRoutes from "./appRoutes";
import * as AuthLoadable from "./loadRoutes/auth.load";
import * as AdminLoadable from "./loadRoutes/admin.load";

function Application() {
  const { isLoggedIn } = useUserInfo();
  return (
    <Routes>
      {isLoggedIn ? (
        <Route element={<ProtectedRoute />}>
          <Route element={<PermissionsLayout />}>
            <Route
              element={<AdminLoadable.Dashboard />}
              path={AppRoutes.DASHBOARD}
            />
            <Route
              element={<AdminLoadable.FolderContainer />}
              path={`${AppRoutes.FOLDER_CONTAINER}`}
            />
            <Route
              element={<AdminLoadable.FolderContainer />}
              path={`${AppRoutes.FOLDER_CONTAINER}/:folder`}
            />

            <Route
              element={<AdminLoadable.FolderContainer />}
              path={`${AppRoutes.FOLDER_CONTAINER}/:folder/:id`}
            />

            <Route
              element={<AdminLoadable.FolderContainer />}
              path={`${AppRoutes.FOLDER_CONTAINER}/:id`}
            />

            <Route
              element={<AdminLoadable.ControlPanel />}
              path={`${AppRoutes.CONTROLPANEL}`}
            />
            <Route
              element={<AdminLoadable.MangerUsers />}
              path={`${AppRoutes.MANAGERUSERS}`}
            />

            <Route
              element={<AdminLoadable.SearchDocument />}
              path={`${AppRoutes.SEARCH_DOCUMENT}`}
            />
            <Route
              element={<AdminLoadable.MangerGroups />}
              path={`${AppRoutes.MANGERGROUPS}`}
            />
            <Route
              element={<AdminLoadable.MangerIndexes />}
              path={`${AppRoutes.MANGERINDEXES}`}
            />
            <Route
              element={<AdminLoadable.ManagerSetting />}
              path={`${AppRoutes.MANAGERSETTING}`}
            />
            <Route
              element={<AdminLoadable.ManagerAnnoucement />}
              path={`${AppRoutes.MANAGERANNOUCEMENT}`}
            />
            <Route
              element={<AdminLoadable.ControlPanel />}
              path={`${AppRoutes.CONTROLPANEL}`}
            />
            <Route
              element={<AdminLoadable.NewDocument />}
              path={`${AppRoutes.NEW_DOCUMENT}`}
            />
            <Route
              element={<AdminLoadable.NewDocument />}
              path={`${AppRoutes.NEW_DOCUMENT}/:id`}
            />
            <Route
              element={<AdminLoadable.Profile />}
              path={`${AppRoutes.PROFILE}`}
            />
            <Route
              element={<AdminLoadable.Setting />}
              path={`${AppRoutes.SETTING}`}
            />
            <Route
              element={<AdminLoadable.DocumentApproval />}
              path={`${AppRoutes.DOCUMENT_APPROVAL}`}
            />
            <Route
              element={<AdminLoadable.Workflow />}
              path={`${AppRoutes.WORKFLOW}`}
            />
            <Route
              element={<AdminLoadable.Spooler />}
              path={`${AppRoutes.SPOOLER}`}
            />
            <Route
              element={<AdminLoadable.FolderContainer />}
              path={`${AppRoutes.FOLDER_CONTAINER}/:folder`}
            />
            <Route
              element={<AdminLoadable.DocumentViewer />}
              path={`${AppRoutes.DOCUMENT_VIEWER}/:path/:id`}
            />
          </Route>
        </Route>
      ) : null}
      <Route element={<AuthLoadable.Login />} path={AppRoutes.LOGIN} />
      <Route element={<AuthLoadable.Sample />} path={AppRoutes.SAMPLE} />
      <Route element={<AuthLoadable.Signup />} path={AppRoutes.SIGNUP} />
    </Routes>
  );
}

export default Application;
