const AppRoutes = {
  LOGIN: "/",
  SIGNUP: "/signup",
  DASHBOARD: "/",
  SETTINGS: "/settings",
  FOLDER_CONTAINER: "/folder-container",
  DOCUMENT_VIEWER: "/document-viewer",
  CONTROLPANEL: "/control-panel",
  MANAGERUSERS: "/manager-users",
  MANGERGROUPS: "/manger-groups",
  MANGERINDEXES: "/manger-indexes",
  MANAGERANNOUCEMENT: "/manger-annoucement",
  MANAGERSETTING: "/manger-setting",
  NEW_DOCUMENT: "/new-document",
  PROFILE: "/profile",
  SEARCH_DOCUMENT: "/search-document",
  SETTING: "/setting",
  DOCUMENT_APPROVAL: "/document-approval",
  SPOOLER: "/spooler",
  WORKFLOW: "/workflow",
  SAMPLE: "/sample",
};

export default AppRoutes;
