/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { useAppDispatch } from "redux/store";


export interface ICIdsState {
  cids: any[];
  bread: any[];
  accessPermission: any[];
}

const initialState: ICIdsState = {
  cids: [],
  bread: [],
  accessPermission: [],
};

interface IPayload {
  cid?: any;
  bread?: any[];
  accessPermission?: any;
}

interface IAction {
  payload: IPayload;
  type: string;
}
const cidSlice = createSlice({
  name: "container.slice",
  initialState,
  reducers: {
    setSelectedContainers: (state, action: IAction) => {
      if (action?.payload?.cid) {
        const isExist = state.cids?.find(
          (x: any) => x.cid === action?.payload?.cid.cid,
        );
        if (isExist) {
          const filtered = state.cids?.filter(
            (x) => x.cid !== action.payload.cid.cid,
          );
          state.cids = filtered;
        } else {
          state.cids = [...state.cids, action.payload.cid];
        }
      }
    },
    setSelectedBread: (state, action: IAction) => {
      state.bread = action.payload.bread || [];
    },
    resetSelectedContainers: (state) => {
      state.cids = [];
      state.bread = [];
    },

    setAccessPermission: (state, action: IAction) => {
      state.accessPermission = action.payload.accessPermission || [];
    },
  },
});

export const {
  setSelectedContainers,
  resetSelectedContainers,
  setSelectedBread,
  setAccessPermission,
} = cidSlice.actions;

export const useCidActions = () => {
  const dispatch = useAppDispatch();
  return {
    setSelectedContainers: (payload: IPayload) =>
      dispatch(setSelectedContainers(payload)),
    setSelectedBread: (payload: IPayload) =>
      dispatch(setSelectedBread(payload)),

    resetSelectedContainers: () => dispatch(resetSelectedContainers()),
    setAccessPermission: (payload: IPayload) =>
      dispatch(setAccessPermission(payload)),
  };
};
const { reducer } = cidSlice;
export default reducer;
