import Close from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";

type IProps = {
  open: boolean;
  title: string;
  message: string;
  cancelText: string;
  confirmText: string;
  handleClose: () => void;
  onConfirm: () => void;
  width?: string; // Add width prop
  height?: string;
  buttonText?: string;
  isClose?: boolean;
  bgColor?: string;
};

function Alert(props: IProps) {
  const {
    open,
    title,
    message,
    handleClose,
    onConfirm,
    cancelText,
    confirmText,
    width,
    height,
    buttonText,
    isClose,
    bgColor,
  } = props;

  return (
    <Dialog
      // fullWidth
      // maxWidth="sm"
      open={open}
      sx={{
        minWidth: width || "450px",
        minHeight: height || "350px",
        margin: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center", // Center the IconButton horizontally
          alignItems: "center",
          width: "40px",
          height: "40px",
          mt: "24px",
          ml: "24px",
          background: isClose ? "#FEE2E2" : "#DAF3D6",
          borderRadius: "50%",
        }}
      >
        {isClose ? (
          <IconButton onClick={handleClose}>
            <Close sx={{ color: "red" }} />
          </IconButton>
        ) : (
          <IconButton onClick={handleClose}>
            <CheckIcon sx={{ color: "#19B600" }} />
          </IconButton>
        )}
      </Box>
      <DialogTitle
        sx={{
          fontFamily: "Inter",
          fontSize: "14px",
          fontWeight: 600,
          paddingTop: "24px",
        }}
      >
        {title}
      </DialogTitle>

      <DialogContent>
        <Typography
          sx={{ fontFamily: "Inter", fontSize: "14px", fontWeight: "Regular" }}
        >
          {message}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ paddingBottom: "24px" }}>
        <Button
          color="primary"
          sx={{
            fontSize: "16px",
            fontFamily: "Inter",
            fontWeight: "Medium",
            borderColor: "#D1D5DB",
            color: "#374151",
            borderRadius: "4px",
            height: "40px",
          }}
          variant="outlined"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          sx={{
            fontSize: "16px",
            fontFamily: "Inter",
            fontWeight: "Medium",
            background: bgColor || "#B95757",
            borderRadius: "4px",
            height: "40px",
          }}
          variant="contained"
          onClick={onConfirm}
        >
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Alert;
